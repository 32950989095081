.upload-box > p{
text-align: center;
font-weight: 500;
margin-top: 10px;
}

.icon {
    width: 90px;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.upload-box {
    width: 100%;
}

.upload-form {
    display: flex;
    margin-top: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 2px dashed var(--red);
    margin: 2rem 4rem;
    border-radius: 1rem;
    cursor: pointer;
}

.upload-form :where(i,p){
    color: var(--red);
}

.upload-form i{
    font-size: 50px;
}

.upload-form p{
    margin-top: 15px;
    font-size: 16px;
}

section .row {
    margin-top: 10px;
    background: #d5ebff;
    list-style: none;
    padding: 10px;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section .row i {
    color: var(--red);
    font-size: 30px;
}

section .details span {
    font-size: 12px;
}

.loading-area .row .content {
    width: 100%;
    margin-left: 15px;
}

.loading-area .details{
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    justify-content: space-between;
}

.loading-area .content .loading-bar{
    height: 6px;
    width: 100%;
    margin-bottom: 4px;
    background: #fff;
    border-radius: 30px;
}

.content .loading-bar .loading{
    height: 100%;
    width: 10%;
    background: var(--red);
    border-radius: inherit;
}

.uploaded-area {
    overflow-y: scroll;
}

.uploaded-area.onprogress{
    max-height: 150px;
}

.uploaded-area::-webkit-scrollbar {
    width: 0px;
}

.uploaded-area .row .content {
    display: flex;
    align-items: center;
}

.uploaded-area .row .details {
 display: flex;
 margin-left: 15px;
 flex-direction: column;
}

.uploaded-area .row .details  .size{
color: #404040;
font-size: 11px;
}
.uploaded-area i.fa-check{
    font-size: 16px;
}