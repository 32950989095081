.leftSide {
    background-color: var(--black);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    color: aliceblue;
    padding: 1rem 0.5rem;
}

.menu {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: 0;
}



.menu ul li {
    list-style: none;
    font-size: 17px;
    padding: 0.5rem;
    margin-left: 0;
    align-items: center;
    font-weight: 200;
}

.menu ul:last-child {
    padding-bottom: 0;
}

.menu ul li a:hover {
    color: white;
}

.menuInfo{
    margin-top: 0;
}

.menuInfo ul li {
    list-style: none;
    font-size: 15px;
    padding: 0.3rem 0;
    cursor: pointer;
}