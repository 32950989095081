.new{
    width: 100%;
    display: flex;
}

.newContainer{
    flex: 6;
}

.newTop, .newBottom{
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 20px;
    margin: 20px;
    display: flex;
}

.newTop-title{
    color: lightgrey;
    font-size: 20px;
    font-weight: 500;
}


.new-bottomLeft{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
.new-bottomLeft img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    align-self: center;
}
.new-bottomRight {
    flex: 2;
}


.newForm{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.newForm .newFormInput {
  width: 40%;
}

.newFormInput label {
    display: flex;
    align-items: center;
}

.newForm .icon {
  cursor: pointer;
}

.newFormInput input {
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 1px solid gray;
}


.newForm button {
  width: 150px;
  padding: 10px;
  border: none;
  background-color: teal;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}