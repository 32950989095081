.b-container {
    flex-wrap: wrap;
}

.blog-card {
    width: 30%;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
                 rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.blog-card img {
    width: 100%;
    height: 14rem;
    object-fit: cover;
    margin: 1rem 0;

}

.blog {
    display: flex;
    justify-content: space-between;
}
.desc {
    color: var(--lightgrey);
    margin-bottom: 1rem;
    line-height: 23px ;
}