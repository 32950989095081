footer{
    background-color: var(--232f3e);
    color: white;
  }
  
  footer:not(:first-child){
    border: 1px solid rgba(255, 255,255, 0.1) ;
  }
  
  footer .input-group-text{
    background-color: var(--color-232f3eS);
    color: white;
    padding: 8px 17px;
  }
  
  
  footer .button{
    background-color: var(--color-232f3e);
    color: white;
    font-size: 14px;
    padding: 1rem 2rem;
    border-radius: 1.4rem;
  }
  
  .button:hover{
    background-color: var(--red);
    color: #131921;
  }