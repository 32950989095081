.CategoriesPage{
    display: flex;
    width: 100%;
}

.CategoriesPage-container{
    flex: 6;
}

.CategoriesPage-container .categorie .button{
    margin: 1rem;
}