.rightSide{
    background-color: var(--black);
    display: flex;
    flex-direction: column;
    color: aliceblue;
    height: 100vh;
}

.profilImg {
    display: flex;
    justify-content: center;
}


.profilImg img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    align-self: center;
} 
.ProfileInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    gap: 10px;
}

.ProfileInfo span:nth-child(1){
    font-size: 20px;
    font-weight: 500;
}

.trophyimage{
  display: flex;  
  margin-top: 1rem;
  justify-content: center;
}

.trophyimage img{
    width: 100px;
    align-self: center;
    object-fit: cover;
}

.trophyInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
}

