.widget {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 20px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.41);
    ;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.41);
    border-radius: 10px;
    height: 150px;

}
.widget-left, .widget-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.widget .widget-left .widget-title {
    font-size: 14px;
    font-weight: 500;
    color: grey;
}
.widget .widget-left .widget-counter{
    font-size: 28px;
    font-weight: 300;
}

.widget-link{
    width: max-content;
    font-size: 12px;
    border-bottom: solid 1px gray;
}

.widget-right .percentage{
    display: flex;
    align-items: center;
    font-size: 14px;

}
.percentage &.positive{
    color: green;
}
.percentage &.nagative{
    color: red;
}

.widget-icon{
    font-size: 22px;
    background-color: var(--red);
    align-self: flex-end;
    border-radius: 5px;
    color: aliceblue;
}