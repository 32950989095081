.v-wrapper{
    position: relative;
}

.v-container{
    background-color: aliceblue;
    display: flex;
    padding: 2rem;
}

.ville{
    display: inline-block;
}

.v-head {
    position: relative;
    z-index: 1;
}

.btn-ville{
    margin: 0 ;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(6, 2fr);
}


.btn-ville li{
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 0.5rem;

}

.btn-ville li button{
    padding: 1rem 3rem;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    border-radius: 3rem;
}

.btn-ville li button:hover{
    color: aqua;
}

.btn-ville li:nth-child(even) button{
    background-color: var(--black);
}