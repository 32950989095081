.dropdownProfile {
    position: absolute;
    top: 7.5rem;
    right: 14.5em;
    background-color: var(--black);
    width: 12rem;
    z-index: 1000;
    border-radius: 1rem;
    border: 1px solid gray;
}

/* .dropdownProfile::before {
    content: '';
    position: absolute;
    top: -0.7rem;
    right: 1.1rem;
    width: 25px;
    height: 25px;
    background-color: var(--black);
    border-left: 1px solid gray;
    border-top: 1px solid gray;
    transform: rotate(45deg);
} */

.dropdownProfile ul li {
    list-style: none;
    cursor: pointer;
    padding-bottom: 5px;
    border-bottom: solid 0.5px grey;
}

.dropdownProfile ul li:nth-last-child(1){
    border-bottom: none;
}

.dropdownProfile ul:first-child {
    margin-top: 1.2rem;
}

.profileName {
    cursor: pointer;
}


@keyframes slide-down {
    from {
      transform: translateY(-100%); /* Démarre en étant en haut, hors de la vue */
    }
    to {
      transform: translateY(0); /* Termine en étant à sa position normale */
    }
  }

@media screen and (max-width: 1280px) {
    .dropdownProfile {

        animation: slide-down 0.4s ease-in-out;
        color: red;
        position: absolute;
        top: 5.5rem;
        right: 0;
        background-color: #ffffff;
        width: 100%;
        z-index: 1000;
        /* border-radius: 1rem; */
        border: 1px solid gray;
    }
}