.admin-navbar {
    display: flex;
    height: 50px;
    border: 1px solid lightskyblue;
    align-items: center;
    font-size: 14px;
    color: grey;

}
.admin-navbar .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    justify-content: space-between;
}

.wrapper .search{
    display: flex;
    align-items: center;
    border: solid 0.5px lightgray;
    padding: 3px;
}

.wrapper .search input{
    bottom: none;
    outline: none;
    background: transparent;

    
}
.wrapper .search input &&::placeholder{
    font-size: 12px;
}

.wrapper .items{
    display: flex; 
    align-items: center;
}

.wrapper .items .item {
    display: flex;
    align-items: center;
    margin: 20px;
    position: relative;

}
.wrapper .items .item .icon{
    font-size: 22px;
    cursor: pointer;
}

.wrapper .items .item .avatar{
    height: 30px;
    width: 30px;
    border-radius: 50%;
}


.wrapper .items .item .counter {
    height: 15px;
    width: 15px;
    background-color: var(--red);
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center; 
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    top: -5px;
    right: -5px;
}
