.admin-sidebar{ 
    flex: 1;
    border-right: 0.5px solid lightgray;  
    background-color: white;
    height: 100%;

}
.admin-sidebar .top{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.top .adminlogo{
    font-size: 25px;
    font-weight: bold;
    color: var(--red);
}

.adminlogo hr{
    height: 0;
    border: 0.5px solid lightgray;
}

.center{
    padding-left: 0.7rem;
}

.center ul{
    list-style: none;
    margin: 0;
    padding: 0;

}
.center ul .title{ 
    font-size: 15px;
    font-weight: bold;
    color: #999;
    margin-bottom: 5px;
    margin-top: 10PX;
}

.center ul li{
display: flex;
align-items: center;
padding: 8px;
cursor: pointer;

} 

.center ul li:hover{
    background-color: lightcoral;
    color: white;
}

.center ul li .icon{
    font-size: 22px;
    color: var(--red);
}


.center ul li span{
    font-size: 15px;
    font-weight: 500;
    margin-left: 8px;
}

.admin-sidebar .bottom{
    display: flex;
    align-items: center;
    margin: 10px;

}
.colorOption{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid lightskyblue;
    cursor: pointer;
    margin-left: 5px;

}
.colorOption:nth-child(1){
    background-color: whitesmoke;
}
.colorOption:nth-child(2){
    background-color: #333;

}
.colorOption:nth-child(3){
    background-color: darkblue;
}

