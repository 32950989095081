/* .mainSide {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0;
    width: 100%;
    margin: 0;
    padding: 0;
} */

.search-bar {
    background-color: white;
    border-radius: 1rem;
    border: 3px solid rgba(120, 120,120, 0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;
    margin-top: 2rem;
}
.results-list {
    margin-top: 0;
    width: 100%;
    background-color: #fff;
    color: var(--red);
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: 1rem;
    font-size: 17px;
    font-weight: 500;
    max-height: 250px;
    overflow-y: scroll;
}

.search-bar2 {
    background-color: white;
    /* border-radius: 3rem; */
    border: 3px solid rgba(120, 120, 120, 0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
    background-color: var(--black);
    /* margin-top: 0.5rem; */
    border-radius: 0.5rem;
}

.search-bar2 input {
    border: none;
    outline: none;
    width: 60%;
    background-color: var(--black);
    color: aliceblue;
}


/* PROJECT */



/* Hero */

.dashboardHero {
    width: 100%;
    height: 20rem;
    background-image:
    url(../../../public/images/service2.jpg);
    /* background-color: var(--black); */
    margin-top: 1rem;
    border-radius: 0.6rem;
    background-size: cover;
    background-position: center;
}

/* Top catégories */

.caroussel {
    overflow: hidden;
    position: relative;
    padding: 2rem
}

.t-head {
    margin-bottom: 2rem;
}

.tc-card1 {
    gap: 0.5rem;
    padding: 1rem 0.5rem;
    max-width: max-content;
    border-radius: 10px;
    margin: auto;
    transition: 300ms all ease-in;
    align-items: center;
    position: relative;
    overflow: hidden!important;
}

.tc-card1 img {
    width: 100%;
    width: 13rem;
    border-radius: 1rem;
    height: 200px;
    object-fit: cover;
    overflow: hidden!important;
}

.tc-card1 span {
    position: absolute;
    bottom: 15px;
    left: auto;
    background-color: rgba(0, 0, 0, 0.20);
    align-items: center;
    padding: 1rem 3.2rem;
    overflow: hidden!important;
}

.tc-card1 .primaryText {
    font-size: 1.5rem;
    align-self: center;
    color: #FFFFFF;
}

.tc-card1:hover {
    scale: 1.025;
    cursor: pointer;
    background: linear-gradient(180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(136, 160, 255, 0.46) 217.71%);
    box-shadow: 0px 71px 49px -51px rgba(136, 160, 255, 0.21);
}

