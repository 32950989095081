 .login{
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .L-wrapper{
    width: 80%;
    height: 75%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    margin: 5rem 0;
 }

 /* .left, .right{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
 } */

 .form{
   flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
 }


 .center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
 }

 .or{
    border: 2px solid grey;
    border-radius: 50%;
    padding: 10px;
    color: grey;
    background-color: white;
    font-weight: bold;
 }

 .line {
    width: 0.5px;
    height: 70%;
    background-color: lightgray;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
 }

 .login-button{
    width: 250px;
    padding: 15px 25px;
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 1rem;
    cursor: pointer;
 }

 .icon{
    width: 20px;
    height: 20px;
    margin-right: 10px;
 }

 .google{
    background-color: #df4930;
 }

 .facebook{
    background-color: #507cc0;
 }

 .linkedin{
    background-color: black;
 }

 .infoInput {
   border: none;
   outline: none;
   border-radius: 10px;
   padding: 20px;
   flex: 1;
   background-color: lightgray;
 }

 .right>div{
   display: flex;
   gap: 1rem;
   height: 3.5rem;
   width: 90%;
 }

 .right  span{
   cursor: pointer;
 }

 .submit{
    width: 90%;
    padding: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
 }

 .login-title{
    color: lightgray;
    position: absolute;
    top: 15rem;
    left: 33%;
    margin: auto;
 }

 .bodylogo{
   width: 250px;
   height: auto;
 }

 .message{
   margin-top: 0;
   align-self: center;
   cursor: pointer;
 }

 .errorMessage{
   align-items: center;
   border-radius: 0.7rem;
 }


 .errorMessage>p{
   font-size: 15px;
   color: red;
   margin-bottom: 0;
   text-align: center;
 }