.artisan-form-wrrapper {
    background-image:
        linear-gradient(rgba(255, 255, 255, 0.7), 
        rgba(255, 255, 230, 0.7)),
        url(./formback.jpg);
    background-size: cover;
    padding: 6rem 5rem;
}
.form-container{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media screen and  (max-width: 1280px) {
    .artisan-form-wrrapper {
        padding: 3rem 2rem;
    }
}