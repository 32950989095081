.M-artisan-wrapper{
    position: relative;
    background-color: mintcream;
    
}

.artisan-home{
    display: block;
    justify-content: center;
    align-items: center;
}

.main-title h1{
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3rem;
    margin-bottom: 2rem;
}

.artisan-home .search-bar {
    background-color: white;
    border-radius: 3rem;
    border: 3px solid rgba(120, 120,120, 0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 75%;
}

.search-bar input{
    border: none;
    outline: none;
    width: 60%;
}
.search-bar button{
    border-radius: 2rem;
    padding: 0.5rem 2rem;
}

.artisan-search-bar{
    align-self: center;
    display: flex;
    justify-content: center;
}

.project-card{
    padding: 1rem;
    margin: 1rem  auto;
    width: 80%;
    display: flex;
    gap: 1rem;
    background: aliceblue;
    cursor: pointer;
    border-radius: 1rem;
}

.project-img{
   width: 100px; 
}

.project-details{
    width: 80%;
}


.project-card h1{
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-bottom: 1rem;
}

.project-card span  {
    display: flex;
    align-items: center;
}
