.espace-pro {
    position: relative;
    display: grid;
    grid-template-columns: 20% 49% 29%;
    gap: 1rem;
    background: mintcream;
    padding: 1rem;
}

.espace-pro-right {
    display: flex;
    border-radius: 2rem;
}

.artisan-profile-card {
    position: relative;
    width: 100%;
    background: white;
    border-radius: 2rem ;
    padding: 1.8rems;
    box-shadow: 0 5px 10 rgba(0, 0, 0, 0.1);
    margin: 1rem;
}

.upper-container{
    height: 100px;
    background-color: var(--red);
    border-radius: 1rem 1rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.upper-container .image {
    display: flex;
    align-items: center;
}



.image .profile-img {
    margin-top: 4rem;
    background: white;
    width: 120px !important;
    height: 120px;
    padding: 3px;
    border-radius: 50%;
    max-width: 300px !important;
}



.upload-b{
    position: absolute;
    top: 8rem;
    left: 6rem;
}


.artisan-profile-card .text-data{
    margin-top: 6rem ;
    margin-left: 1rem;
    margin-bottom: 2rem;
    margin-right: 1rem;

}

.text-data .heading{
    display: flex;
    justify-content: space-between;
}

.heading-icon{
    background-color: aqua;
    color: white;
    font-size: 22px;
    cursor: pointer;
}


.text-data label{
    font-size: 17px;
    font-weight: 500;
}

.text-data span{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.card-info {
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
  margin: 1rem;
}

