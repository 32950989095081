@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css");


.bar{
    width: 100%;
    height: 35.56px;
    display: flex;
    justify-content: center;
}

.bar .container{
    display: flex;
    justify-content: center;
}
.bar span:not(:first-child){
    padding-left: 3em;
    white-space: 80px;
}


.top-header-wrapper {
    background-color: var(--black);
    padding: 0.1rem 1rem;
    align-items: center;
}

.logo{
    filter: invert(0);
    width: 150px;
}
.menu-bottom .btn{
    text-transform: capitalize;
    font-size: larger;
    font-weight: 500;
}
.artisan_button{
    padding: 20px;
    border: 0;
    box-sizing: 0;
    box-shadow: none;
    outline: none;
}
.innerWidthHeader{
    left: 0;
    right: 0;
    width: 90%;
    margin: auto;
  }
  

.h-container {
    gap: 1rem;
    color: var(--secondary);
    justify-content: space-between;
}
.bar span .btn{
    padding: 7.2px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    box-sizing: 0;
    box-shadow: none;
    outline: none;
    border-radius: 0;
    font-weight: 600;
}

.header-upper-links {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.header-upper-links a:hover{
    color: red;
}


.input-group-text {
    background-color: var(--red);
    border: none;
}

.bottom-header-wrapper {
    background-color: var(--red);
    padding: 0.5rem 0.5rem;
}

.b-container{
    justify-content: center;
}


.menu-links a,
.dropdown button {
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
}

.dropdown button:focus{
    border: none;
    box-shadow: none;
  }

.dropdown-menu {
    background-color: var(--red);
    font-size: 1.1rem;
    border: inherit;
}
.dropdown-item{
    padding: 0.7rem;
  }
  
  
.dropdown-item:hover{
    background-color: transparent;
}

@media screen and  (max-width: 1280px) {
    .top-header-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .innerWidthHeader{
        width: 95%;
    }

    .bar{
    display: none;
    }
}


@media screen and (max-width: 768px) {
    .innerWidthHeader{
        width: 100%;
    }
    .logo{
        filter: invert(0);
        width: 100px;
    }

    .bottom-header-wrapper {
        display: none;
    }
}

