.upload-box{
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;

}

.upload-box .top {
    text-align: center;
}

.upload-box p{
    font-weight: bold;
    color: var(--red);
}

.upload-box button {
    outline: 0;
    border: 0;
    color: aliceblue;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 400;
    padding: 8px 13px;
    background-color: var(--red);
}

.upload-box .drag-area{
    height: 150px;
    border-radius: 5px;
    border: 1px dashed var(--red);
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
}

.upload-box .drag-area .visible{
    font-size: 18px;
}

.upload-box .select {
    color: #5256ad;
    margin-left: 5px;
    cursor: pointer; 
    transition: 0.4s;
}

.upload-box .container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
}


.upload-box .container .image{
    width: 75px;
    margin-right: 5px;
    height: 75px;
    position: relative;
    margin-bottom: 8px;
}

.upload-box .container .image img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: contain;
}

.upload-box .container .image span{
    position: absolute;
    top: -2px;
    right: 1px;
    font-size: 20px;
    cursor: pointer;
    background-color: white;
}

.upload-box input,
.upload-box .drag-area .on-drop, 
.upload-box .drag-area .dragover .visible{
    display: none;
}

.delete {
    z-index: 999;
    color: var(--red );
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .popup-content button {
    margin-top: 10px;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background: #0056b3;
  }
  