.t-container{
  overflow: hidden;
  position: relative;
}

.t-head{
    margin-bottom: 2rem;
}

.tc-card {
    gap: 0.5rem;
    padding: 1rem 0.5rem;
    max-width: max-content;
    border-radius: 5px;
    margin: auto;
    transition: 300ms all ease-in;
  }
  
  .tc-card img {
    width: 100%;
    width: 14rem;
    border-radius: 0.6rem;
    height: 200px;
    object-fit: cover;
  }

  .tc-card .primaryText{
    font-size: 1.5rem;
  }

  .tc-card:hover {
    scale: 1.025;
    cursor: pointer;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(136, 160, 255, 0.46) 217.71%
    );
    box-shadow: 0px 71px 49px -51px rgba(136, 160, 255, 0.21);
  }

  .swiper-horizontal {
    overflow: visible;
  }

  .s-button{
    position: absolute;
    gap: 1rem;
    bottom: 20rem;
    right: 1rem;
}

.s-button button{
  font-size: 1.2rem;
  padding: 0.2rem 0.8rem;
  color: blue;
  border: none;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}


.s-button :nth-child(1){
  background-color: #EEEEFF;
}

.s-button:nth-child(2){
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}
