
.project-card{
    margin: 1rem  auto;
    display: flex;
    gap: 1rem;
    background: aliceblue;
    cursor: pointer;
    border-radius: 1rem;
}

.project-img{
   width: 80px; 
}

.project-details{
    width: 80%;
}


.project-card h1{
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-bottom: 1rem;
}

.project-card span  {
    display: flex;
    align-items: center;
}
