 .hero-wrapper{
     color:white;
     position: relative;
     padding-bottom: 2rem;
     background-color: var(--black);
     background-position: center;
     background-size: cover;
 }


 .hero-container{
    justify-content: space-between;
    align-items: flex-End;
    height: 100;
 }


 /* left side */
 .hero-left{
    margin-top: 3rem;
    width: 100%;
 }

 .hero-title{
    position: relative;
    z-index: 1;
    padding: 3rem 0 ;
 }

 .hero-left h1{
    font-weight: 600;
    font-size: 2.3rem;
    line-height: 2.5rem;
}


/* .hero-container  .hero-left .search-bar {
    display: flex;
    background-color: white;
    border-radius: 1rem;
    border: 3px solid rgba(120, 120,120, 0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;
    margin-top: 2rem;
} */
.hero-container  .hero-left .results-list {
    margin-top: 0;
    width: 100%;
    background-color: #fff;
    color: var(--red);
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: 1rem;
    font-size: 17px;
    font-weight: 500;
    max-height: 250px;
    overflow-y: scroll;
}

.result-items {
cursor: pointer;

}

.hero-container  .hero-left .search-bar input{
    border: none;
    outline: none;
    width: 75%;
    color: black;
}
.search-bar button{
    border-radius: 2rem;
    padding: 0.5rem 2rem;
}

 .image-container{
    width: 35rem;
    height: 40rem;
    overflow: hidden;
 }

 .image-container img{
    width: 100%;
    height: 100%; 
 }

 .right-side {
    border-top: 2px solid var(--red);    /* Bordure supérieure rouge */
    border-right: 2px solid var(--red); /* Bordure droite verte */
    border-bottom: 2px solid var(--grey);  /* Bordure inférieure bleue */
    border-left: 2px solid  var(--grey);  
 }


 
@media (max-width: 640px) {

    

    .hero-title>h1{
    font-size: 1.5rem;
    line-height: 4rem;
    }
    .image-container{
        width: 95%;
        height: 25rem;
        justify-content: center;
    }
    .stats{
        justify-content: center;
        gap: 1.5rem;
    }

    .hero-container .hero-left{
        width: 100%;
    }

    .hero-container  .hero-left .search-bar input{
        border: none;
        outline: none;
        width: 40%;
        color: black;
    }

    .stat>:nth-child(1){
        font-size: 1.5rem;
    }
    .stat>:nth-child(2){
        font-size: 0.8rem;
    }

}
