.datatable{
    padding: 30px;
}

.CellWithImg {
    display: flex;
    align-items: center;
}

.cellImg{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}

.datatableTitle {
    width: 100%;
    font-size: 22px;
    color: grey;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datatableTitle .link{
    color: green;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid green;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;

}

.cellWithStatus{
    padding: 5px;
    border-radius: 5px;
}
.active{
    background-color: rgba(0, 128, 0, 0.05);
    color: green;
}

.pending {
    background-color: rgba(255, 217, 0, 0.05);
    color: goldenrod;
  }

.passive {
    background-color: rgba(255, 0, 0, 0.05);
    color: crimson;
}


.cellAction{
    display: flex;
    align-items: center;
    gap: 15px;
}

.viewButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: darkblue;
    border: 1px dotted darkblue;
    cursor: pointer;
}
.deleteButton{
    padding: 2px 5px;
    border-radius: 5px;
    color: crimson;
    border: 1px dotted crimson;
    cursor:pointer;
}