.post{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post .post-img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin-bottom: 1rem;
}

.post-long-desc{
    padding: 2rem;
}
 