.single {
    display: flex;
    width: 100%;
}

.singleContainer{
    flex: 6;
}

.signleTop {
    padding: 20px; 
    display: flex;
    gap: 20px;

}

.singleLeft{
flex: 1;
-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 20px;
    position: relative;
}

.singleText{
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 500;
}

.editButton{
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    font-size: 14px;
    color: #7451f8;
    background-color: #7551f818;
    cursor: pointer;
    border-radius: 0 0 0 5px;
}

.singleItem{
    display: flex;
    gap: 20px;
}

.singleItem .itemImg{
    width: 100px;
    height: 100px;
    border-radius: (50%);
    object-fit: cover;
}




.singleRight{
    flex: 2;
}

.itemTitle {
    margin-bottom: 10px;
    color: #555;
    font-size: 20px;
    font-weight: 500;
}

.detialItem {
    margin-bottom: 7px;
    font-size: 14px;
}

.itemKey{
    font-weight: bold;
    color: grey;
    margin-right: 5px;
}

.itemValue{
    font-weight: 300;
}

.signleBottom{
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 20px;
    margin: 20px;
}
